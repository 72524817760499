<template>
    <div class="case-page">
        <page-title :title="'案件管理'">维护案件信息</page-title>
        <div>
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="全部案件" name="first">
                    <AllCase></AllCase>
                </el-tab-pane>
                <!-- <el-tab-pane label="已派单案件" name="second">
                    <Already></Already>
                </el-tab-pane>
                <el-tab-pane label="未派单案件" name="third">
                    <NoDis></NoDis>
                </el-tab-pane>
                <el-tab-pane label="已完成案件" name="fourth">
                    <Finish></Finish>
                </el-tab-pane> -->
            </el-tabs>
        </div>
    </div>
</template>
<script>
import AllCase from './subpage/allcase.vue';
// import Already from './subpage/already.vue';
// import NoDis from './subpage/nodis.vue';
// import Finish from './subpage/finish.vue';
export default {
    name:"Case",
    components:{
        AllCase
    },
    data(){
        return{
            activeName:"first"
        }
    },
    methods:{
        /**tab点击切换事件 */
        handleClick(){

        }
    }
}
</script>
<style lang="less" scoped>
.case-page{
    /deep/ .el-tabs__item{
        font-size: 16px;
        &:hover{
            color: #0ba882;
        }
    }
    /deep/ .el-tabs__item.is-active{
        color: #0ba882;
        font-weight: bold;
    }
    /deep/ .el-tabs__active-bar{
        background-color: #0ba882;
    }
}
</style>